// bootstrap dependencies
import { Collapse } from "bootstrap";
// flickity
import "flickity";
// jquery
const $ = require("jquery");

(function () {
    'use strict';
    // variables declaration
    const heroLinks = $('.hero-link');
    const navLinks = $('.nav-link');
    // methods
    const scrollTo = function(e) {
        let target = $(this.hash);
        if (target.length) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top
            }, {
                duration: 600,
            });
        }
    }
    const isPageCurrent = function() {
        navLinks.each( function() {
            if ( $(this).attr("href") === window.location.pathname ) {
                $(this).attr("aria-current", "page");
            }
        })
    }
    // inits & event listeners
    isPageCurrent();
    heroLinks.on('click', scrollTo);
})();